import { render, staticRenderFns } from "./BookingPositionAccepted.vue?vue&type=template&id=73cbeb1f&scoped=true&"
import script from "./BookingPositionAccepted.vue?vue&type=script&lang=js&"
export * from "./BookingPositionAccepted.vue?vue&type=script&lang=js&"
import style0 from "./BookingPositionAccepted.vue?vue&type=style&index=0&id=73cbeb1f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73cbeb1f",
  null
  
)

export default component.exports